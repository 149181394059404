.user-info-wrapper {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-info-wrapper img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  font-size: 14px;
  font-weight: 500;
  color: #454545;
}

.user-info span:last-child {
  font-weight: 400;
}
