@import "bootstrap/dist/css/bootstrap.min.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 84px);
  max-width: 1100px;
  margin: auto;
}

.chat-area {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  padding-top: 132px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.chat-area::-webkit-scrollbar {
  width: 5px;
}
.chat-area::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 132px;
  margin-bottom: 24px;
}
.chat-area::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 2px;
}

.input-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: #ffffff; /* Set background color to match the InputBar */
}

a {
  text-decoration: none;
}

@media (max-width: 1024px) {
  .chat-area {
    padding-top: 108px;
  }
  .chat-area::-webkit-scrollbar-track {
    margin-top: 108px;
  }
}

@media (max-width: 768px) {
  .chat-area {
    padding-top: 96px;
  }
  .chat-area::-webkit-scrollbar-track {
    margin-top: 96px;
  }
}
